import { MdVisibility, MdModeEdit } from "react-icons/md"
import { CheckIcon } from "gatsby-interface"
import { BoltIcon } from "../../shared/icons/BoltIcon"

export const cloudFeatures = [
  {
    emText: `Incremental Builds`,
    text: `1000x faster builds`,
    Icon: BoltIcon,
  },
  {
    emText: `CMS Previews`,
    text: ` real-time updates`,
    Icon: MdModeEdit,
  },
  {
    emText: `Deploy Previews`,
    text: ` shareable URLs`,
    Icon: MdVisibility,
  },
  {
    emText: `Performance Reports`,
    text: ` powered by Lighthouse`,
    Icon: CheckIcon,
  },
]
