import React from "react"
import { generateId } from "../../shared/helpers"

export const GatsbyCloudLogo = ({ width = 148, height = 148, ...rest }) => {
  const gradientId = generateId()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 148 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        cx="51.375"
        cy="58.375"
        r="13.875"
        fill="#159BF3"
        stroke="#159BF3"
        strokeWidth="6.9375"
      />
      <circle cx="56" cy="30.625" r="4.625" fill="#159BF3" />
      <circle cx="23.625" cy="58.375" r="4.625" fill="#159BF3" />
      <circle cx="32.875" cy="35.25" r="4.625" fill="#159BF3" />
      <path
        d="M52.5313 71.0975C52.5311 72.2571 52.6037 73.4156 52.7486 74.5662C52.6037 73.4156 52.5311 72.2571 52.5313 71.0975C52.5379 69.3549 52.7129 67.6171 53.0539 65.9082"
        stroke="#663399"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.54 65.8702C106.311 59.5446 102.918 53.8443 97.9439 49.7472C92.9701 45.65 86.7255 43.4116 80.2815 43.416C73.8375 43.4204 67.5959 45.6673 62.6277 49.7711C57.6595 53.875 54.2744 59.58 53.0532 65.9072C50.7069 64.7824 48.1374 64.2007 45.5354 64.2052C42.9335 64.2098 40.366 64.8005 38.0237 65.9334C35.6814 67.0664 33.6244 68.7126 32.0056 70.7496C30.3868 72.7867 29.2477 75.1622 28.6729 77.6999C28.0982 80.2376 28.1026 82.8721 28.6858 85.4079C29.2689 87.9436 30.4159 90.3154 32.0415 92.3471C33.6671 94.3787 35.7295 96.018 38.0756 97.1432C40.4217 98.2684 42.9911 98.8505 45.5931 98.8464H66.4056"
        fill="white"
      />
      <path
        d="M107.54 65.8702C106.311 59.5446 102.918 53.8443 97.9439 49.7472C92.9701 45.65 86.7255 43.4116 80.2815 43.416C73.8375 43.4204 67.5959 45.6673 62.6277 49.7711C57.6595 53.875 54.2744 59.58 53.0532 65.9072C50.7069 64.7824 48.1374 64.2007 45.5354 64.2052C42.9335 64.2098 40.366 64.8005 38.0237 65.9334C35.6814 67.0664 33.6244 68.7126 32.0056 70.7496C30.3868 72.7867 29.2477 75.1622 28.6729 77.6999C28.0982 80.2376 28.1026 82.8721 28.6858 85.4079C29.2689 87.9436 30.4159 90.3154 32.0415 92.3471C33.6671 94.3787 35.7295 96.018 38.0756 97.1432C40.4217 98.2684 42.9911 98.8505 45.5931 98.8464H66.4056"
        stroke={`url(#${gradientId})`}
        strokeWidth="6"
        strokeLinejoin="round"
      />
      <circle
        r="33.5312"
        transform="matrix(-1 0 0 1 98.4375 91.9375)"
        fill="white"
        stroke="white"
        strokeWidth="6.9375"
      />
      <path
        d="M120.5 91.875H106.5V95.875H116.1C114.7 101.875 110.3 106.875 104.5 108.875L81.4996 85.875C83.8996 78.875 90.6996 73.875 98.4996 73.875C104.5 73.875 109.9 76.875 113.3 81.475L116.3 78.875C112.3 73.475 105.9 69.875 98.4996 69.875C88.0996 69.875 79.2996 77.275 77.0996 87.075L103.5 113.475C113.1 111.075 120.5 102.275 120.5 91.875Z"
        fill="white"
      />
      <path
        d="M76.5 92.0752C76.5 97.6752 78.7 103.075 82.9 107.275C87.1 111.475 92.7 113.675 98.1 113.675L76.5 92.0752Z"
        fill="white"
      />
      <path
        d="M98.5 63.875C83.1 63.875 70.5 76.475 70.5 91.875C70.5 107.275 83.1 119.875 98.5 119.875C113.9 119.875 126.5 107.275 126.5 91.875C126.5 76.475 113.9 63.875 98.5 63.875ZM82.9 107.475C78.7 103.275 76.5 97.675 76.5 92.275L98.3 113.875C92.7 113.675 87.1 111.675 82.9 107.475ZM103.3 113.275L77.1 87.075C79.3 77.275 88.1 69.875 98.5 69.875C105.9 69.875 112.3 73.475 116.3 78.875L113.3 81.475C109.9 76.875 104.5 73.875 98.5 73.875C90.7 73.875 84.1 78.875 81.5 85.875L104.5 108.875C110.3 106.875 114.7 101.875 116.1 95.875H106.5V91.875H120.5C120.5 102.275 113.1 111.075 103.3 113.275Z"
        fill="#663399"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="106.875"
          y1="58.3777"
          x2="60.6246"
          y2="58.3777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#663399" />
          <stop offset="1" stopColor="#159BF3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
